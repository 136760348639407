<!--
 * @Author: 娄松
 * @Date: 2025-02-11 17:30:59
 * @LastEditors: 娄松
 * @LastEditTime: 2025-02-19 16:07:34
 * @FilePath: \dcy-web\apps\admin\pages\teaching-management\course-management\detail\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang="ts">
import dayjs from 'dayjs'
import { getCourseInstanceDetailReq } from '~/services/courseManage'
import { semesterOptionList } from '~/constants/config'

const route = useRoute()
const router = useRouter()

const detail = ref({})

const courseInstanceId = computed(() => route.query.courseInstanceId)

const getSemesterName = (semesterNumber) => {
  return semesterOptionList.find(v => v.value === semesterNumber)?.label
}

const menus = ref([
  { key: 'course-basic', label: '课程基本信息', icon: 'icon-Property2wenjian' },
  { key: 'course-detail', label: '课程详情', icon: 'icon-Property1kecheng' },
  { key: 'course-class', label: '课程班', icon: 'icon-jiaose' },
  { key: 'course-goal-achievement', label: '课程目标达成', icon: 'icon-ticheng' },
])

const isActiveMenu = menu => route.path.split('/').includes(menu.key)

const linkToMenu = (menu) => {
  router.replace({
    path: `/teaching-management/course-management/detail/${menu.key}`,
    query: route.query,
  })
}

// 课程详情用顶部导航栏的布局方式 /course-detail/xxx
// 本来想用嵌套layout实现，结果发现刷新问题，只能放在一个页面判断
const isCourseDetail = computed(() => {
  return route.path.split('/').includes('course-detail')
})

const courseDetailTopMenuItems = ref([
  { key: 'knowledge-graph', label: '知识图谱', permissionId: 1 },
  { key: 'outline', label: '大纲', permissionId: 2 },
  { key: 'teaching-plan', label: '教案', permissionId: 3 },
  { key: 'chapter', label: '章节', permissionId: 4 },
  { key: 'task', label: '作业', permissionId: 6 },
  { key: 'experiment', label: '实验', permissionId: 7 },
  { key: 'exam', label: '考试', permissionId: 8 },
  { key: 'material', label: '资料', permissionId: 9 },
])

// 课程实例详情
const { run: runGetCourseInstanceDetail } = useRequest(getCourseInstanceDetailReq, {
  // false 为自动执行
  manual: true,
  // 请求成功
  onSuccess: (res) => {
    detail.value = res
    courseDetailTopMenuItems.value = courseDetailTopMenuItems.value.filter((v: any) => {
      return res.moudle.includes(v.permissionId)
    })
  },
  // 请求失败
  onError: () => {},
})

const selectedTab = ref(route.path.split('/').pop())
watch(
  () => route.path,
  (newValue) => {
    const path = newValue.split('/').pop()
    if (newValue === '/teaching-management/course-management/detail/course-detail') {
      // 有权限的模块路径
      const permissionKey = courseDetailTopMenuItems.value.find(v =>
        detail.value?.moudle?.includes(v.permissionId),
      )?.key
      router.replace({
        path: `/teaching-management/course-management/detail/course-detail/${permissionKey}`,
        query: route.query,
      })
      return
    }
    if (newValue.includes('chapter/preview'))
      selectedTab.value = 'chapter'
    else selectedTab.value = path
  },
)

const linkToCourseDetailSubMenu = (item: { key: string }) => {
  selectedTab.value = item.key
  router.push({
    path: `/teaching-management/course-management/detail/course-detail/${item.key}`,
    query: route.query,
  })
}

runGetCourseInstanceDetail({
  id: courseInstanceId.value,
})
</script>

<template>
  <div class="course-layout h-full flex flex-col bg-[#F5F7F9]">
    <div
      class="bg-[#fff] px-[1.75rem] py-[1.25rem]"
      :style="{ borderBottom: '1px solid #E2E8F0' }"
    >
      <div class="text-[1rem] font-bold text-[#0F172A]">
        {{ detail?.courseInstanceName }}
      </div>
      <div class="mt-[0.75rem] flex text-[0.88rem] text-[#334155] space-x-[1.25rem]">
        <div>{{ getSemesterName(detail?.semesterNumber) }}</div>
        <div>
          {{ dayjs(detail?.startTime).format("YYYY-MM-DD") }} 至
          {{ dayjs(detail?.endTime).format("YYYY-MM-DD") }}
        </div>
        <div>课时：{{ detail?.lessonHours }}</div>
        <div>主持教师：{{ detail?.teacherInfoDTO?.teacherName }}</div>
      </div>
    </div>
    <div class="flex flex-1">
      <div class="w-[12.5rem] bg-[#fff] px-[0.75rem] py-[1.5rem]">
        <div
          v-for="item in menus"
          :key="item.key"
          class="mb-[0.5rem] h-[2.5rem] w-[11rem] flex cursor-pointer items-center rounded-[0.5rem] pl-[1rem] text-[0.88rem]"
          :style="{
            background: isActiveMenu(item) ? '#3F79FE' : '#fff',
            boxShadow: isActiveMenu(item) ? '0.13rem 0.13rem 0.63rem 0 #CEDDFF' : 'none',
            fontWeight: isActiveMenu(item) ? '700' : '500',
            color: isActiveMenu(item) ? '#fff' : '#1E293B',
          }"
          @click="linkToMenu(item)"
        >
          <i :class="`iconfont ${item?.icon} mr-[0.5rem] text-[1rem] font-normal`" />
          {{ item.label }}
        </div>
      </div>
      <div class="m-[1.25rem] w-full overflow-x-hidden rounded-[0.5rem] bg-[#fff]">
        <!-- <a-tabs v-if="isCourseDetail" v-model="selectedTab" :trigger="trigger" @change="handleTabChange">
          <a-tab-pane v-for="item in courseDetailTopMenuItems" :key="item.key" :title="item.label" />
        </a-tabs> -->
        <a-menu v-if="isCourseDetail" mode="horizontal" :selected-keys="[selectedTab]">
          <a-menu-item
            v-for="item in courseDetailTopMenuItems"
            :key="item.key"
            @click="linkToCourseDetailSubMenu(item)"
          >
            {{ item.label }}
          </a-menu-item>
        </a-menu>
        <NuxtPage v-show="!isCourseDetail && detail?.id" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.course-layout {
  :deep(.arco-menu) {
    border-bottom: 1px solid var(--color-neutral-3);
  }
  :deep(.arco-menu-inner) {
    padding: 10px 10px 10px 0 !important;
  }
  :deep(.arco-menu-pop) {
    display: none;
  }
  :deep(.arco-menu-selected-label) {
    height: 2px !important;
    bottom: -10px !important;
  }
}
</style>
